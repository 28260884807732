<template>
  <v-container fluid>
    <v-row no-gutters class="pr-0">
      <v-col cols="12" md="4" sm="6">
        <v-card class="fill-height pr-0">
          <v-card-title>Dimension Example</v-card-title>
          <v-card-text class="d-flex justify-content-center align-items-center px-4 h-100 w-100 box-border-sizing">
              <div class="w-100">
              <v-img
                contain
                max-width="700px"
                  class="justify-center mx-0 my-4 w-100"
                :src="require(`@/assets/dimensions/${logo}.png`)"
                :key="logo"
                alt
              />
              <div v-if="showDepthText" class="elevator-depth-text">
                <p>{{ textContent }}</p>
              </div>
              </div>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <v-form ref="form" v-model="valid">
          <v-card class="fill-height pr-0">
            <v-card-text>
            <v-text-field disabled outlined hide-details v-model="address" value="" placeholder="Delivery Address" eager />
              <v-card
              class="mb-4 mt-2"
                color="#FFFFFF"  
              >
                <v-card-title class="heading-column">Elevator Interior</v-card-title>
                <v-card-text class="pb-1 pt-4">
                  <v-text-field
                    v-for="(next, i) in elevator"
                    :key="'elevator' + i"
                    v-model="next.value"
                    class="pr-14 mb-3"
                    hide-details
                    dense
                    outlined
                    type="number"
                    :label="next.label"
                    :id="'internal' + next.label"
                    min="0"
                    value=""
                    :suffix="unit"
                    @focus="dimensionFocus"
                    @blur="dimensionFocus"
                  />
                </v-card-text>
              </v-card>

              <v-card
              class="mb-4"
                color="#FFFFFF"
              >
                <v-card-title class="heading-column">Elevator Door Area</v-card-title>
                <v-card-text class="pb-1 pt-4">
                  <v-text-field
                    v-for="(next, i) in door"
                    :key="'door' + i"
                    v-model="next.value"
                    class="pr-14 mb-3"
                    hide-details
                    dense
                    outlined
                    type="number"
                    :label="next.label"
                    :id="'door' + next.label"
                    min="0"
                    value=""
                    :suffix="unit"
                    @focus="dimensionFocus"
                    @blur="dimensionFocus"
                  />
                </v-card-text>
              </v-card>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- :disabled="!isValid()" -->
              <v-btn
                color="primary"
                @click="calculate"
                :disabled="isSubmitted"
                >Submit</v-btn
              >
            </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>

      <v-col cols="12" md="4" sm="6" class="position-relative">
        <!-- <div class="loading-screen" v-if="isShownLoader">
          <div>
            <img src="../assets/calculator.gif">
          </div>
        </div> -->
        
        <!-- Added Loader -->
          <v-overlay 
              :class="isShownLoader ? 'loading-screen' : '' " 
              :value="isShownLoader">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
          </v-overlay>

        <v-card height="800px" style="overflow-y: scroll; overflow-x: hidden;">
          <v-card-title>Result</v-card-title>
          <v-card-text class="pl-0" v-if="isSubmitted">
            <v-span class="heading-result" >Dimensions Submitted Successfully</v-span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="shareCustomerDialog" max-width="500px">
          <v-card>
            <v-card-title>Link not found</v-card-title>
            <v-card-text>
              <v-span class="heading-result" >this link already submitted or  order id is incorrect</v-span>
            </v-card-text>
          </v-card>
          </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import URLSearchParams from '@ungap/url-search-params';

export default {
  name: 'ElevatorMateCustomer',
  components: {},
  props: {
    orderId: { type: String },
    address: { type: String },
    itemType: { type: String },
    unit: { type: String, default: 'mm' },
    language: { type: String, default: 'en' },
  },
  data: () => ({
    isShownLoader: false,
    isSubmitted: false,
    shareCustomerDialog: false,
    valid: false,
    showDepthText: false,
    textContent: "",
    showSupport: false,
    rules: [(value) => !!value || 'Required.'],
    logo: 'elevator',
    icons: Array,
    customerInfo: {},
    elevator: {
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
    },
    door: {
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
    }
  }),
  watch: {
    customerInfo(newCustomerInfo) {
      if(newCustomerInfo.address){
      this.address = newCustomerInfo.address;
      }
    }
  },
  mounted() {
    /*
    if (localStorage.getItem('elevator')) {
      try {
        this.elevator = JSON.parse(localStorage.getItem('elevator'));
      } catch (e) {
        localStorage.removeItem('elevator');
      }
    }
    if (localStorage.getItem('door')) {
      try {
        this.door = JSON.parse(localStorage.getItem('door'));
      } catch (e) {
        localStorage.removeItem('door');
      }
    }
    if (localStorage.getItem('item')) {
      try {
        this.item = JSON.parse(localStorage.getItem('item'));
      } catch (e) {
        localStorage.removeItem('item');
      }
    }*/
    this.reset();
    this.calculate(null);
    this.getOrderId(this.orderId);
  },
  methods: {
    /*
    saveElevator() {
      localStorage.setItem('elevator', JSON.stringify(this.elevator));
    },
    saveDoor() {
      localStorage.setItem('door', JSON.stringify(this.door));
    },
    saveItem() {
      localStorage.setItem('item', JSON.stringify(this.item));
    },*/

    clearResults() {
      this.icons = new Array();
    },
    isValid() {
      //this.$refs.form.validate();
      let values = [
        this.elevator.depth.value,
        this.elevator.height.value,
        this.elevator.width.value,
        this.door.depth.value,
        this.door.height.value,
        this.door.width.value,
      ];
      let valid = true;
      values.forEach(function(item) {
        if (item === null){
          valid = false;
          return;
        }
        else if (item === undefined){
          valid = false;
          return;
        }
        else if (item <= 0){
          valid = false;
          return;
        }
      })
      return valid;
    },
    reset() {
      this.valid = false;
      this.clearResults();
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    dimensionFocus(event) {
      event === undefined;
      switch (document.activeElement) {
        case document.getElementById('internalDepth'):
          this.logo = 'internal_depth';
          this.textContent = "Please consider the elevator handrail at the back of the elevator"
          this.showDepthText = true;
          break;
        case document.getElementById('internalHeight'):
          this.logo = 'internal_height';
          this.textContent = "Please consider lighting, fire sensors, alarms and sprinklers"
          this.showDepthText = true;
          break;
        case document.getElementById('internalWidth'):
          this.logo = 'internal_width';
          this.textContent = "Please consider the side handrails"
          this.showDepthText = true;
          break;
        case document.getElementById('doorDepth'):
          this.logo = 'door_depth';
          this.textContent = "This is the measurment when the door is closed. If not sure PLEASE leave a minimum of a 1mm..";
          this.showDepthText = true;
          break;
        case document.getElementById('doorHeight'):
          this.logo = 'door_height';
          this.textContent = "Please ensure that the elevator lands flush and level with the floors that are to be involved with the intended movement of the item/s";
          this.showDepthText = true;
          break;
        case document.getElementById('doorWidth'):
          this.logo = 'door_width';
          this.textContent = "Please make sure that the doors open to the full extent of the dimension provided";
          this.showDepthText = true;
          break;
        default:
          this.logo = 'elevator';
          this.showDepthText = false;
          break;
      }
    },

    async formatScenarios(response) {
      //this.saveElevator();
      //this.saveDoor();
      //this.saveItem();
      var item;
      this.clearResults();
      for (item in response.data.scenarios) {
        this.icons.push(response.data.scenarios[item].icon);
      }
    },

    async getOrderId(id) {
      this.isShownLoader = true;
      event === undefined;
      var api = `https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/scenarios/getCustomerInfoById?order_id=${id}`;
      const params = new URLSearchParams();
      // call api to calculate scenarios
      axios
        .get(api, params)
        .then(response => {
          this.isShownLoader = false;
          if(response.data.Items[0]){
          console.log(response.data.Items[0]);
          if(!response.data.Items[0].internal_depth){
          this.customerInfo = response.data.Items[0];
          }else{
            this.shareCustomerDialog=true;
          }
          }else{
            this.shareCustomerDialog=true;
          }
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.isShownLoader = false;
    },

    async calculate(event) {
      if (!this.isValid()) {
        this.clearResults();
        return;
      }
      this.isShownLoader = true;
      event === undefined;
      var api = 'https://api.elevatormate.com/v1/scenarios';
      const params = new URLSearchParams();
      params.append('status', "pending");
			params.append('item_type', this.itemType);
      params.append('unit', this.unit);
      params.append('language', this.language);
      params.append('internal_depth', this.elevator.depth.value);
      params.append('internal_height', this.elevator.height.value);
      params.append('internal_width', this.elevator.width.value);
      params.append('door_depth', this.door.depth.value);
      params.append('door_height', this.door.height.value);
      params.append('door_width', this.door.width.value);
      for (const [key, value] of Object.entries(this.customerInfo)) {
          // Append the key-value pair to the URLSearchParams object
          params.append(key, value);
      }
			console.log(params);
      // call api to calculate scenarios
      axios
        .post(api, params)
        .then(response => {
          this.isShownLoader = false;
          console.log(response);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
          window.location.reload();
        });
        this.isShownLoader = false;
        this.isSubmitted = true;
        await this.newDockSubmit(event);
    },


    async newDockSubmit(event) {
      event === undefined;
      var api = 'https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/dock/update';
      const params = new URLSearchParams();
      if(this.address)
      params.append('Address', this.address);
      if(this.elevator.height.value)
      params.append('ElevatorHeight',this.elevator.height.value);
      if(this.elevator.width.value)
      params.append('ElevatorWidth',this.elevator.width.value);
      if(this.elevator.depth.value)
      params.append('ElevatorDepth',this.elevator.depth.value);
      if(this.door.height.value)
      params.append('DoorHeight',this.door.height.value);
      if(this.door.width.value)
      params.append('DoorWidth',this.door.width.value);
      if(this.door.depth.value)
      params.append('DoorDepth',this.door.depth.value);
			console.log(params);
      // call api to calculate scenarios
      axios
        .post(api, params)
        .then(response => {
          console.log(response);
          this.$emit('update-prop-event', () => ({}));
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    
  },
};
</script>
<style scoped>
  .heading-column
  {
    padding: 10px;
    background: #aaa;
  }

  .heading-result
  {
    padding: 10px 20px;
    font-size: 16px;
  }

  .item-column
  {
    padding: 10px;
    background: #e8bc8c;
  }
  .w-100
  {
    width: 100%;
  }
  .h-100
  {
    height: calc(100% - 64px);
  }
  .box-border-sizing
  {
    box-sizing: border-box;
  }
  .position-relative
  {
    position: relative;
  }
  .elevator-depth-text
  {
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  box-sizing: border-box;
  text-align: center;
  }
  .elevator-depth-text p
  {
  width: 100%;
  text-align: center;
  padding: 20px 10px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  }
  .loading-screen
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, .8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .loading-screen img
  {
    width: 70px;
  } */
</style>