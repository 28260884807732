<template>
<div > 
  <v-dialog persistent v-model="addNewDock" max-width="800px">
          <v-card>
            <v-toolbar flat>
    <v-toolbar-title>Enter Dock Information 1</v-toolbar-title>
    <v-spacer></v-spacer> <!-- This will push the export button to the right -->
    <v-btn v-bind="attrs"  color="blue" class="ml-2"
        @click="onExport()"
         dark>
              Export
        </v-btn>
  </v-toolbar>
            <v-card-text class="mt-2">
              <v-form ref="newDockForm" class="form-horizontal">
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field" :rules="[rules.required]" label="Building Name" outlined ref="BuildingName" id="BuildingName"
                  name="BuildingName" required v-model="BuildingName" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field" :rules="[rules.required]" label="Address" outlined ref="Address" id="Address"
                  name="Address" required v-model="Address" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field" label="Loading Dock Address" outlined ref="LDAddress" id="LDAddress"
                  name="LDAddress"  v-model="LDAddress" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Loading Dock Telephone" outlined ref="LDTelephone" id="LDTelephone"
                  name="LDTelephone"  v-model="LDTelephone" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Loading Dock Email" outlined ref="LDEmail" id="LDEmail"
                  name="LDEmail"  v-model="LDEmail" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />


                <v-text-field class="text-field"  label="Email Status" outlined ref="EmailStatus" id="EmailStatus"
                  name="EmailStatus"  v-model="EmailStatus" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Max Height" outlined ref="MaxHeight" id="MaxHeight"
                  name="MaxHeight"  v-model="MaxHeight" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Duration" outlined ref="Duration" id="Duration"
                  name="Duration"  v-model="Duration" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Parking Fee" outlined ref="Parkingfee" id="Parkingfee"
                  name="Parkingfee"  v-model="Parkingfee" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Booking Procedure" outlined ref="BookingProcedure" id="BookingProcedure"
                  name="BookingProcedure"  v-model="BookingProcedure" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Elevator Height" outlined ref="ElevatorHeight" id="ElevatorHeight" min="0" type="number"
                  name="ElevatorHeight"  v-model="ElevatorHeight" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Elevator Width" outlined ref="ElevatorWidth" id="ElevatorWidth" min="0" type="number"
                  name="ElevatorWidth"  v-model="ElevatorWidth" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                
                 <v-text-field class="text-field"  label="Elevator Depth" outlined ref="ElevatorDepth" id="ElevatorDepth" min="0" type="number"
                  name="ElevatorDepth"  v-model="ElevatorDepth" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />
                </v-row>
                
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Door Height" outlined ref="DoorHeight" id="DoorHeight" min="0" type="number"
                  name="DoorHeight"  v-model="DoorHeight" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Door Width" outlined ref="DoorWidth" id="DoorWidth" min="0" type="number"
                  name="DoorWidth"  v-model="DoorWidth" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />

                <v-text-field class="text-field"  label="Door Depth" outlined ref="DoorDepth" id="DoorDepth" min="0" type="number"
                  name="DoorDepth"  v-model="DoorDepth" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />  
                </v-row>
                <v-row justify="space-between" align="center" class="mt-0" >
                <v-text-field class="text-field"  label="Detail" outlined ref="Detail" id="Detail"
                  name="Detail"  v-model="Detail" value="" @change="validateNewDockForm" placeholder="Enter Customer Name" eager />  

                </v-row>
                
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" text @click="closeandResetNewDockDialog">Close</v-btn>
              <v-btn :disabled="invalidnewDockForm" color="primary" text @click="newDockSubmit">Create</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
  <v-card flat >
  
  <v-row justify="space-between" align="center" class="ma-3" >
      <v-col cols="8">
        <v-sheet class="row-header">
          Loading Dock Database
        </v-sheet>
      </v-col>

      <v-col class="d-flex">
        <v-sheet class="item-col-2 pa-0 ma-0">
          <v-text-field
          class="pa-0 ma-0"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      single-line
      variant="outlined"
    ></v-text-field>
        </v-sheet>
        
        <v-btn v-bind="attrs"  color="blue" class="ml-2"
        @click="
        addNewDock=true;
        "
         dark>
              Add New
        </v-btn>
      </v-col>
    </v-row>
    
  </v-card>
  <v-data-table
      class="custom-data-table"
      :headers="headers"
      :items="rows"
      :search="search"
      @click:row="onRowClick"
    >
  
    </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  export default {
  name: 'DockTable',
  components: {},
  props: {
    elevator: {
      type: Object,
      default: () => ({
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
      }),
    },
    door: {
      type: Object,
      default: () => ({
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
      }),
    },
    },
    data () {
      return {
        search: '',
        invalidnewDockForm: true,
        addNewDock: false,
        rules: {
        required: (value) => !!value || 'Required.',
        BuildingName: "",
        Address: "",
        LDAddress: "",
        LDTelephone: "",
        LDEmail: "",
        EmailStatus: "",
        MaxHeight: "",
        Duration: "",
        Parkingfee: "",
        BookingProcedure: "",
        ElevatorHeight: "",
        ElevatorWidth: "",
        ElevatorDepth: "",
        DoorHeight: "",
        DoorWidth: "",
        DoorDepth: "",
        Detail: "",
      },
        headers: [
          { value: 'BuildingName', text: 'Building Name' },
          { value: 'Address', text: 'Address' },
          { value: 'LDAddress', text: 'Dock Address' },
          { value: 'LDTelephone ', text: 'Dock Telephone' },
          { value: 'LDEmail', text: 'Dock Email' },
          { value: 'MaxHeight', text: 'Max Height' },
          { value: 'Duration', text: 'Duration' },
          { value: 'BookingProcedure ', text: 'Booking Procedure' },
          { value: 'ElevatorHeight' ,text:"E-Height"},
          { value: 'ElevatorWidth' ,text:"E-Width"},
          { value: 'ElevatorDepth' ,text:"E-Depth"},
          { value: 'DoorHeight' ,text:"D-Height"},
          { value: 'DoorWidth' ,text:"D-Width"},
          { value: 'DoorDepth' ,text:"D-Depth"},
          { value: 'Detail' ,text:"Detail"},
            
        ],
        rows: [],
      }
      
    },
    created() {
    },
    mounted() {
    this.getDockData();
    },
    methods: {
    // Ensure settings form is validated upon settings change
    validateNewDockForm() {
      if (this.$refs.newDockForm.validate()) {
        this.invalidnewDockForm = false;
      } else {
        this.invalidnewDockForm = true;
      }
    },
    closeNewDockDialog() {
      this.addNewDock = false;
      this.closeandResetNewDockDialog();
    },
    
    closeandResetNewDockDialog() {
        this.addNewDock = false;
        this.BuildingName= "";
        this.Address= "";
        this.LDAddress= "";
        this.LDTelephone= "";
        this.LDEmail= "";
        this.EmailStatus= "";
        this.MaxHeight= "";
        this.Duration= "";
        this.Parkingfee= "";
        this.BookingProcedure= "";
        this.ElevatorHeight= "";
        this.ElevatorWidth= "";
        this.ElevatorDepth= "";
        this.DoorHeight= "";
        this.DoorWidth= "";
        this.DoorDepth= "";
        this.Detail= "";
    },

    onRowClick(item) {
      if(item.BuildingName)
      this.BuildingName = item.BuildingName;
      if(item.Address)
      this.Address = item.Address;
      if(item.LDAddress)
      this.LDAddress = item.LDAddress;
      if(item.LDTelephone)
      this.LDTelephone = item.LDTelephone;
      if(item.LDEmail)
      this.LDEmail = item.LDEmail;
      if(item.EmailStatus)
      this.EmailStatus = item.EmailStatus;
      if(item.MaxHeight)
      this.MaxHeight = item.MaxHeight;
      if(item.Duration)
      this.Duration = item.Duration;
      if(item.Parkingfee)
      this.Parkingfee = item.Parkingfee;
      if(item.BookingProcedure)
      this.BookingProcedure = item.BookingProcedure;
      if(item.ElevatorHeight)
      this.ElevatorHeight = item.ElevatorHeight;
      if(item.ElevatorWidth)
      this.ElevatorWidth = item.ElevatorWidth;
      if(item.ElevatorDepth)
      this.ElevatorDepth = item.ElevatorDepth;
      if(item.DoorHeight)
      this.DoorHeight = item.DoorHeight;
      if(item.DoorWidth)
      this.DoorWidth = item.DoorWidth;
      if(item.DoorDepth)
      this.DoorDepth = item.DoorDepth;
      this.addNewDock = true;
    },

    onExport() {
      this.$emit('update-prop-event', this.Address);
      if(this.ElevatorHeight)
        this.elevator.height.value = this.ElevatorHeight
      if(this.ElevatorWidth)
        this.elevator.width.value  = this.ElevatorWidth
      if(this.ElevatorDepth)
        this.elevator.depth.value  = this.ElevatorDepth
      if(this.DoorHeight)
        this.door.height.value  = this.DoorHeight
      if(this.DoorWidth)
        this.door.width.value  = this.DoorWidth
      if(this.DoorDepth)
        this.door.depth.value  = this.DoorDepth
      this.$emit('update-prop-event-dock', () => ({}));

    },

    async getDockData() {
      this.isShownLoader = true;
      event === undefined;
      var api = `https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/dock/getInfo`;
      const params = new URLSearchParams();
      // call api to calculate scenarios
      axios
        .get(api, params)
        .then(response => {
          this.isShownLoader = false;
          if(response.data.Items){
            this.rows = response.data.Items
          }
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.isShownLoader = false;
    },

    async newDockSubmit(event) {
      this.isShownLoader = true;
      event === undefined;
      var api = 'https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/dock/add';
      const params = new URLSearchParams();
      if(this.BuildingName)
      params.append('BuildingName',this.BuildingName);
      if(this.Address)
      params.append('Address',this.Address);
      if(this.LDAddress)
      params.append('LDAddress',this.LDAddress);
      if(this.LDTelephone)
      params.append('LDTelephone',this.LDTelephone);
      if(this.LDEmail)
      params.append('LDEmail',this.LDEmail);
      if(this.EmailStatus)
      params.append('EmailStatus',this.EmailStatus);
      if(this.MaxHeight)
      params.append('MaxHeight',this.MaxHeight);
      if(this.Duration)
      params.append('Duration',this.Duration);
      if(this.Parkingfee)
      params.append('Parkingfee',this.Parkingfee);
      if(this.BookingProcedure)
      params.append('BookingProcedure',this.BookingProcedure);
      if(this.ElevatorHeight)
      params.append('ElevatorHeight',this.ElevatorHeight);
      if(this.ElevatorWidth)
      params.append('ElevatorWidth',this.ElevatorWidth);
      if(this.ElevatorDepth)
      params.append('ElevatorDepth',this.ElevatorDepth);
      if(this.DoorHeight)
      params.append('DoorHeight',this.DoorHeight);
      if(this.DoorWidth)
      params.append('DoorWidth',this.DoorWidth);
      if(this.DoorDepth)
      params.append('DoorDepth',this.DoorDepth);
			console.log(params);
      // call api to calculate scenarios
      axios
        .post(api, params)
        .then(response => {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.closeandResetNewDockDialog();
    },
    }
  }
</script>
<style scoped>
  .heading-column
  {
    padding: 10px;
    background: #aaa;
  }

  .item-column
  {
    padding: 10px;
    background: #e8bc8c;
  }
  .item-col-2
  {
    margin-right: 10px;
    flex-grow: 1;
  }
  .row-header
  {
    font-size: 18px;
  }
  .w-100
  {
    width: 100%;
  }
  .h-100
  {
    height: calc(100% - 64px);
  }
  .box-border-sizing
  {
    box-sizing: border-box;
  }
  .position-relative
  {
    position: relative;
  }
  .text-field
  {
    padding-left: 10px;
    padding-right: 10px;
  }
  .container
  {
    width: 100%;
  }
  .table
  {
  color: #000000;
  background: #ffff;
  }

.custom-data-table {
  margin: 20px;
  overflow-x: auto;
}

/* Adjust column width based on content */
.custom-data-table th,
.custom-data-table td {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Display ... for overflowed content */
}

/* Set minimum width for columns */
.custom-data-table th[data-min-width],
.custom-data-table td[data-min-width] {
  min-width: var(--min-width, 100px);
}
  .loading-screen
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, .8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .loading-screen img
  {
    width: 70px;
  } */
</style>
