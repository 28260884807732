import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import {
    VApp,
    VForm,
    VTextField,
    VSelect,
    VCheckbox,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    VCard,
    transitions
} from 'vuetify'

Vue.use(Vuetify, {
    components: {
        VApp,
        VForm,
        VTextField,
        VSelect,
        VCheckbox,
        VNavigationDrawer,
        VFooter,
        VList,
        VBtn,
        VIcon,
        VGrid,
        VToolbar,
        VCard,
        transitions
    },
    theme: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
    }
})

export default new Vuetify({
});
