<template>
  <v-app>
    <v-app-bar app color="#EEEEEE" dark>
      <div class="d-flex align-center pointer" @click="showDock=false;">
        <v-img alt="Elevator Mate" class="shrink mr-2" contain
          :src="require('./assets/elevator_mate_logo_vector_270x229.png')" transition="scale-transition" width="40" />
        <span class="brand d-none d-sm-flex">ELEVATOR MATE</span>
      </div>

      <v-spacer></v-spacer>
      <v-btn v-bind="attrs" class="ma-2" color="blue"
        href="https://youtu.be/AtaqpTS5wAU"
        target="_blank"
         dark>
             Watch demo video
      </v-btn>
      <div v-if="signedIn && user">
        <v-btn v-bind="attrs" class="ma-2" color="blue"
        @click="refreshSettings"
         dark>
             <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
        <v-menu key="account" rounded="b-lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" class="ma-2" color="blue" dark>
              <v-icon dark>mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item key="item-0" link class="profile-name">
              <v-list-item-title v-text="user? user?.username: ''" ></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-1" v-if="user?.username === 'faisalirfan361@gmail.com' || user?.username === 'ryan@hcotransport.com.au'" link @click="showDock=true;">
              <v-list-item-title v-text="'loading Dock'"></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-1" link @click="submittedDimensionsDialog=true;">
              <v-list-item-title v-text="'View Submitted Dimensions'"></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-1" link @click="shareCustomerDialog=true;">
              <v-list-item-title v-text="'Request elevator dimensions'"></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-1" link @click="termsAndCondition=true;">
              <v-list-item-title v-text="'Terms and Conditions'"></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-1" link @click="contactUs=true;">
              <v-list-item-title v-text="'Contact Us'"></v-list-item-title>
            </v-list-item>
            <v-list-item key="item-2" link @click="signOut">
              <v-list-item-title v-text="'Sign Out'"></v-list-item-title>
            </v-list-item>
            
          </v-list>
          
        </v-menu>

        <v-dialog persistent v-model="termsDialog" max-width="500px">
          <v-card>
            <v-card-title>Do you agree to our terms and conditions?</v-card-title>
            <v-card-text>
              Please read our
              <a href="https://elevatormate.com/share/Conditions+of+Contract+Sept+2015+-+FSR+Authorised+v2.pdf">terms and
                conditions</a>
              and then click AGREE to continue
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" text @click="signOut">Disagree</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text
                @click="
                  termsDialog = false;
                agreedTerms = true;
                settingsDialog = true;
                                                                                                                                                                                                                                                                                                                                                                                                                                  ">Agree</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="settingsDialog" max-width="500px">
          <v-card>
            <v-card-title>Settings</v-card-title>
            <v-card-text>
              <v-form ref="landingForm" class="form-horizontal">
                <v-text-field :rules="[rules.required]" label="Delivery Address" outlined ref="addressBox" id="addressBox"
                  name="addressBox" required v-model="address" value="" @change="validateSettings" placeholder="" eager />
                <v-select :rules="[rules.required]" label="Item Type" outlined name="itemType" id="itemType" required
                  v-model="itemType" :items="itemTypes" item-text="label" item-value="value" @click="focusItemType"
                  @change="validateSettings"></v-select>
                   <!--
                <v-select label="Language" outlined name="language" id="language" required v-model="language"
                  :items="languages" item-text="label" item-value="value"></v-select>
                <v-select label="Measurement" outlined name="unit" id="unit" required v-model="unit" :items="units"
                  item-text="label" item-value="value"></v-select>
                  -->
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="invalidSettings" color="primary" text @click="closeSettingsDialog">Enter</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="shareCustomerDialog" max-width="500px">
          <v-card>
            <v-card-title>Share with Site Contact</v-card-title>
            <v-card-text>
              <v-form ref="shareCustomerForm" class="form-horizontal">
                <v-text-field :rules="[rules.required]" label="Name" outlined ref="nameBox" id="nameBox"
                  name="nameBox" required v-model="name" value="" @change="validateCustomerForm" placeholder="Enter Customer Name" eager />

                  <v-text-field :rules="[rules.required]" label="Email" outlined ref="emailBox" id="emailBox"
                  name="emailBox" required v-model="email" value="" @change="validateCustomerForm" placeholder="Enter Customer Email" eager />

                  <v-text-field :rules="[rules.required]" label="Order Id" outlined ref="orderIdBox" id="orderIdBox"
                  name="orderIdBox" required v-model="order_id" value="" @change="validateCustomerForm" placeholder="Enter Order Id" eager />
                
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
               <v-btn  color="primary" text @click="shareCustomerDialog=false;">Close</v-btn>
              <v-btn :disabled="invalidshareCustomerForm" color="primary" text @click="shareCustomerSubmit">Share</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>

          <!-- Added Loader -->
          <v-overlay 
              :class="isShownLoader ? 'loading-screen' : '' " 
              :value="isShownLoader">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
          </v-overlay>


          <v-dialog persistent v-model="refill" max-width="500px">
          <v-card>
            <v-card-title>Auto-fill Dimensions Detected</v-card-title>
            <v-card-text>
              A saved dimension already exists for the entered address. Would you like to auto-fill the dimension details for this address?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="secondary" text @click="closeAutoFillDialog">Enter Manually</v-btn>
              <v-btn  color="primary" text @click="closeRefillDialog">Auto-fill Details</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
          <v-dialog persistent v-model="submittedDimensionsDialog" max-width="500px">
          <v-card>
            <v-card-title>Submitted Dimensions</v-card-title>
            <v-card-text>
    <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Order Id
          </th>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Email
          </th>
          <th class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in customerData.Items" :key="index">
      <!-- Replace 'item.property1', 'item.property2', etc., with your actual data properties -->
      <td>{{ item.order_id }}</td>
      <td>{{ item.name }}</td>
      <td>{{ item.email }}</td>
      <td class="pointer"  @click="handleSubmittedDimensions(item); submittedDimensionsDialog=false">view</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" text @click="submittedDimensionsDialog=false;">Close</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>

      </div>

      <!-- Added Terms and condition Page -->
      <v-dialog persistent v-model="termsAndCondition" max-width="1000px">
        <v-card class="tncClass">

          <!-- insert T&Cs here -->
          
<h2>Elevatormate (em8) TERMS AND CONDITIONS</h2>

<p>em8 relies on data/measurements from a 3rd external party/user, therefore we do not take any liability or responsibility for items bought as a result of its calculations. By accepting these conditions you agree to waive any liability and/or legal action against Elevatormate pty ltd.</p>
<p>Please also take a moment to read through the following:</p>

<ol>
    <li>
        em8 can only calculate the basic area that you have instructed it to and does not consider such variables as: 
        <ol style="list-style-type: lower-alpha">
            <li>Elevator handrails</li>
            <li>Elevator doors that do not open to the full extent of the measurements it’s given</li>
            <li>Lighting, fire sensors / alarms / sprinklers</li>
            <li>Elevator wall / floor protection</li>
            <li>Weight restrictions of elevator</li>
            <li>em8 assumes that the elevator lands flush and level with the floors that are to be involved with the intended movement of the item/s</li>
        </ol>
    </li>
    <li>
        em8 assumes that the item/s are able to get to the elevator unimpeded in a square and perpendicular manner. Please be sure that any tight corners en route to AND out of the elevator are checked for suitable entry and exit. 
    </li>
    <li>
        em8 assumes that the item/s are going to be exiting out of the same door they entered.
    </li>
    <li>
        em8 only calculates if the item fits into the lift, it does not consider passengers or any other objects. 
    </li>
    <li>
        em8 results are to be used as a guide only. em8 takes no responsibility for items that are bought off its results that have a different outcome as to what em8 indicates. 
    </li>
</ol>
<h2>Privacy Terms and Conditions</h2>
<p>Last Updated: 31.10.23</p>
<p>We are committed to safeguarding your privacy and ensuring the security of your data. By using our Service, you agree to the terms outlined in this Privacy Policy.</p>

<ol>
    <li>
        <p>Terminate account</p>
        <p>We reserve the right to terminate user accounts at our discretion for any reason, including but not limited to:</p>
        <ol style="list-style-type: lower-alpha;">
            <li>Violation of our Terms of Service.</li>
            <li>Suspected fraudulent or unlawful activity.</li>
            <li> Failure to comply with applicable laws and regulations.</li>
            <li>Inactivity on your account for an extended period.</li>
        </ol>
        <p>Upon account termination, you will no longer have access to the Service, and your data may be deleted or retained according to our data retention policies.</p>
    </li>
    <li>
        <p>Privacy and Security for Data Storage</p>
        <ol style="list-style-type: lower-alpha;">
            <li><p>Data Collection</p>
            <p>We collect and store Personal Information, as defined in our Privacy Information section below, to provide and improve our Service. This information may include but is not limited to your name, email address, and other information you voluntarily provide.</p>
            </li>
            <li>
                <p>Data Usage</p>
                <p>We use your Personal Information to provide the Service, communicate with you, and enhance your experience. We will never sell or rent your Personal Information to third parties.</p>
            </li>
            <li>
                <p>Disclosure of Personal Information</p>
                <p>We may be required to disclose your Personal Information by law, by court order, or to investigate suspected fraud or other unlawful activity. We may also disclose your Personal Information to third parties in certain circumstances. When we disclose your Personal Information to our third-party service providers, we will attempt to ensure that those service providers have reasonable data security policies and practices in place.</p>
            </li>
            <li>
                <p>Data Security</p>
                <p>We take reasonable measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please understand that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
            </li>
            <li>
                <p>Data Retention</p>
                <p>We will retain your Personal Information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
            </li>
        </ol>
    </li>
    <li>
        <p>Privacy Information</p>
        <ol style="list-style-type: lower-alpha">
            <li>
                <p>Personal Information</p>
                <p>Personal Information refers to information that can be used to identify an individual, such as name, email address, phone number, or any other information that is personally identifiable.</p>
            </li>
            <li>
                <p>Third-Party Service Providers</p>
                <p>We may utilize third-party service providers to assist in providing our Service. These providers are subject to contractual obligations to protect your Personal Information and may only use it for the purposes we specify.</p>
            </li>
        </ol>
    </li>
    <li>
        <p>Changes to Privacy Policy</p>
        <p>We reserve the right to amend this Privacy Policy at any time. Changes will be posted on this page, and the date of the latest revision will be indicated at the top of this document. Your continued use of the Service after changes have been made constitutes your acceptance of those changes.</p>
    </li>
    <li>
        <p>Contact Us</p>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:hello@elevatormate.com">hello@elevatormate.com</a>.</p>
    </li>
</ol>

          <div>
            <div class="terms-and-conditions termsConditionClass mt-4">
              <v-btn color="primary" class="ms-4" @click="termsAndCondition = false, privacyPolicy = false">Accept
              </v-btn>
            </div>

          </div>

        </v-card>
      </v-dialog>


<!-- Contact Us Page -->
      <v-dialog persistent v-model="contactUs" max-width="500px">
        <v-card class="tncClass">

          <!-- insert T&Cs here -->
          
<h2>{{ title }}</h2>
      <p>{{ quote }}</p>
      <a :href="'mailto:'+contactEmail" >{{ contactEmail }}</a>



          <div>
            <div class="terms-and-conditions termsConditionClass mt-4">
              <v-btn color="secondary" @click="contactUs = false">Close</v-btn>
              
            </div>

          </div>

        </v-card>
      </v-dialog>

    </v-app-bar>

    <v-main>
      <div class="auth-container" v-if="!signedIn && !orderId">
        <v-alert class="alert-dialog" v-show="responseMessage.length > 0" dismissible icon="mdi-alert">{{ responseMessage
        }}</v-alert>
        <div class="section">
          <form @submit.prevent="signIn" @keydown.enter.prevent="signIn" v-if="form_id === 0">
            <h3 class="signin-heading">Sign in to your account</h3>
            <div>
              <label class="label-form">Email*</label>
              <input type="text" v-model="email" class="login-field" placeholder="Enter your email" required />
            </div>
            <div>
              <label class="label-form">Password*</label>
              <input type="password" v-model="password" class="login-field" placeholder="Enter your password" required />
            </div>
            <div class="mb-2rem">
              <p class="login-text">Forgot your password? <button @click="changeForm(3)">Reset Password</button></p>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="login-text mb-0">No account? <button @click="changeForm(1)">Create Account</button></p>
              <button class="submit-btn" :class="{ disabled: button_loader }" type="submit"><span class="text-btn">Sign
                  In</span>
                <span class="loader-btn"> </span></button>
            </div>
          </form>
          <form @submit.prevent="signUp" v-if="form_id === 1">
            <h3 class="signin-heading">Sign up</h3>
            <div class="mb-2rem">
              <label class="label-form">Email Address*</label>
              <input type="email" class="login-field" v-model="email" placeholder="Email" required />
              <label class="label-form">Password*</label>
              <input type="password" class="login-field" v-model="password" placeholder="Password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" required
                @blur="validatePassword" @input="displayValidationError = false" />

              <span class="error-message" v-if="displayValidationError">
                Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special
                character.
              </span>

              <!-- <label class="label-form">Phone Number*</label>
              <input type="number" class="login-field" v-model="phoneNumber" placeholder="Phone number" required /> -->
              <label class="label-form">Industry*</label>
              <!-- <input type="industry" class="login-field" v-model="industry" placeholder="Industry" required /> -->
              <select class="login-field form-select" @change="updateIndustryValue($event.target.value)">
                <option :key="industry.id" v-for="industry in industyList">
                  {{ industry.name }}
                </option>
              </select>
              <div class="terms-and-conditions d-flex align-items-center">
                <input type="checkbox" id="terms" @change="checkboxChanged(termsAccepted)" v-model="termsAccepted"
                  required />
                <label for="terms" class="label-form label-agreement pb-0" style="padding-left: 11px;">By ticking this box
                  you are agreeing to elevatormate's Terms and Conditions</label>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="login-text mb-0">Have an account? <button @click="changeForm(0)">Sign In</button></p>
              <button class="submit-btn" :class="{ disabled: button_loader }" type="submit"><span class="text-btn">Sign Up</span><span class="loader-btn">
                </span></button>
            </div>
          </form>
          <form @submit.prevent="confirmSignUp" v-if="form_id === 2">
            <h3 class="signin-heading">Confirm your email address</h3>
            <label class="label-form">Email Address*</label>
            <input type="text" class="login-field" v-model="email" readonly placeholder="email" required />
            <input type="text" class="login-field" v-model="code" placeholder="Enter your code" required />
            <button class="submit-btn me-4" :class="{ disabled: button_loader }" type="submit"><span class="text-btn">Confirm Sign Up</span><span class="loader-btn">
                </span></button>
            <button class="submit-outline-btn submit-btn" :class="{ disabled: button_loader }" @click="resendConfirmationCode"><span class="text-btn">Resend Code</span><span class="loader-btn">
                </span></button>
          </form>
          <form v-if="form_id === 3" @submit.prevent="forgotPassword">
            <h3 class="signin-heading">Reset your password</h3>
            <label class="label-form">Email Address*</label>
            <input type="email" class="login-field" v-model="email" placeholder="Enter your email" required />
            <div class="d-flex justify-content-between align-items-center">

              <p class="login-text mb-0"><button @click="changeForm(0)">Back to Sign In</button></p>
              <button type="submit" class="submit-btn" :class="{ disabled: button_loader }" ><span class="text-btn">Send Code</span><span class="loader-btn"> </span></button>
            </div>
          </form>
          <form v-if="form_id === 4" @submit.prevent="forgotPasswordSubmit">
            <div>
              <label class="label-form" >Enter Verification Code*</label>
              <input type="text" v-model="code" placeholder="Enter your code" autocomplete="off"  class="login-field" required />
            </div>
            <div>
              <label class="label-form">Enter New Password*</label>
              <input type="password" v-model="newPassword" autocomplete="off"  class="login-field" placeholder="New password" 
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" required 
              @blur="validatePassword"  @input="displayValidationError = false" />

              <span class="error-message" v-if="displayValidationError">
                Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special
                character.
              </span>
            </div>

             <div class="d-flex justify-content-between align-items-center">
              <button type="submit" class="submit-btn me-4" :class="{ disabled: button_loader }"><span class="text-btn">Reset Password</span><span class="loader-btn"> </span></button>
            
              <button @click="changeForm(0)">
                Sign In
              </button>
            </div>
            
          </form>
        </div>
      </div>
      <div v-if="hasDock">
        <DockTable ref="DockTable" :elevator="elevator" :door="door" @update-prop-event="handleUpdateEventExport" @update-prop-event-dock="handleUpdateEventDock" />
      </div>
      <div :class="{ 'blur-content': settingsDialog || termsDialog }" v-if="(signedIn && user && !orderId && !hasDock)">
        <ElevatorMate ref="elevatorMate" @update-prop-event="handleUpdateEvent" :selectedCustomer="selectedCustomer" :unit="unit" :language="language" :item-type="itemType" :address="address"
          :username="user?.username" :refresh="refresh" :showDock="showDock" :elevator="elevator" :door="door" :item="item" />
      </div>
      <div v-if="orderId">
        <ElevatorMateCustomer ref="elevatorMateCustomer" :orderId="orderId" :unit="unit" :language="language" :item-type="itemType" :address="address"
           />
      </div>
    </v-main>
  </v-app>
</template>


<script>
import ElevatorMate from './components/ElevatorMate';
import ElevatorMateCustomer from './components/ElevatorMateCustomer';
import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import DockTable from './components/DockTable';



export default {
  name: 'App',

  components: {
    ElevatorMate,
    ElevatorMateCustomer,
    DockTable
  },
  data() {
    return {
      displayValidationError: false,
      responseMessage: "",
      refresh : 1,
      industyList: [
        { id: 1, name: 'Retail' },
        { id: 2, name: 'Logistics' },
        { id: 3, name: 'Architect' },
        { id: 4, name: 'Interior Designer' },
        { id: 5, name: 'Private' },
        { id: 6, name: 'Other' },
      ],
      agreedTerms: false,
      acceptTerms: false,
      isLoggedIn: false,
      address: '',
      isShownLoader: true,
      refill: false,
      temporaryDimension: {},
      finalAddress: '',
      addressReady: false,
      invalidSettings: true,
      invalidshareCustomerForm: true,
      itemType: null,
      customerData: [],
      selectedCustomer: {},
      itemTypes: [
        { label: 'Sofa', value: 'sofa' },
        { label: 'Entertainment Unit', value: 'entertainment_unit' },
        { label: 'Table', value: 'table' },
        { label: 'Other', value: 'other' }
      ],
      language: 'en',
      languages: [{ label: 'English', value: 'en' }],
      loader: new Loader({
        apiKey: "AIzaSyA_qdi9IfajYsmmWulZciGlYLgcrQiKOiQ"
      }),
      rules: {
        required: (value) => !!value || 'Required.',
      },
      settingsDialog: false,
      shareCustomerDialog: false,
      submittedDimensionsDialog: false,
      orderId: null,
      signedIn: false,
      termsDialog: true,
      termsAndCondition: false,
      privacyPolicy: false,
      contactUs: false,
      title: "Contact Us",
      contactEmail: "hello@elevatormate.com",
      quote: "We'd love to hear from you! Feel free to drop us an email.",
      unit: 'mm',
      units: [
        { label: 'Milimeters', value: 'mm' },
        { label: 'Centimeters', value: 'cm' },
        // { label: 'Inches', value: 'in' }
      ],
      unsubscribeAuth: undefined,
      user: undefined,
      username: undefined,
      valid: false,
      phoneNumber: 765767578,
      email: '',
      password: '',
      code: '',
      newPassword: '',
      industry: 'Retail', // Default value
      codeSent: false,
      termsAccepted: false,
      form_id: 0,
      button_loader: false,
      hasErrorCode: false,
      showDock: false,
      elevator: {
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
    },
    door: {
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
    },
    item: {
      depth: {
        label: 'Depth',
        value: '',
      },
      height: {
        label: 'Height',
        value: '',
      },
      width: {
        label: 'Width',
        value: '',
      },
    },
    };
  },
  computed: {
    hasDock() {
      return this.showDock;
    },
  },
  created() {
    // this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
    if (localStorage.getItem('authData')) {
      this.signedIn = true;
      this.user = JSON.parse(localStorage.getItem('authData'));
      this.settingsDialog = false;
      this.itemType = null;
    }
    // });
  },
  mounted() {
    // Always clear address and itemType on mounted()
    this.address = "";
    this.itemType = null;
    this.getPendingOrders();
    this.invalidSettings = true;
    this.invalidshareCustomerForm = true;
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('orderId')){
      this.orderId = urlParams.get('orderId');
    }
    // If user has previously agreed to terms, show settingsDialog on mounted()
    if (localStorage.agreedTerms) {
      this.termsDialog = false;
      this.settingsDialog = false;
      this.agreedTerms = localStorage.agreedTerms;
    }
    // Load unit and language from local storage
    if (localStorage.unit) this.unit = localStorage.unit;
    if (localStorage.language) this.language = localStorage.language;
    //if (localStorage.itemType) this.itemType = localStorage.itemType;
  },
  updated() {
    // Attach the autocomplete in the updated() hook instead to avoid race conditions
    // If you do this in mounted(), this element isn't loaded in time
    // Then use a sentinel value to make sure it only happens the once
    let that = this;

    var loadAutoComplete = function() {
      that.loader.load().then(async (google) => {
        const { Autocomplete } = await google.maps.importLibrary("places");
        const autocompleteOpts = {
          componentRestrictions: { country: "au" } // Edit this if we go international
        };
        const autocompleteElem = document.getElementById("addressBox");
        autocompleteElem.setAttribute('placeholder' , '');
        const autocomplete = new Autocomplete((autocompleteElem), autocompleteOpts);
        autocomplete.addListener("place_changed", () => {
          setTimeout(() => {
            that.address = autocomplete.getPlace().formatted_address;
            console.log(that.address);
          }, 200); // Slow down the quantity of requests to the API
        });
        that.addressReady = true;
      });
      if (document.getElementById("addressBox") && !that.addressReady) {
        setTimeout(loadAutoComplete, 1000);
      }
    }
    setTimeout(loadAutoComplete, 1000);
    
  },
  watch: {
    agreedTerms(newShowTerms) {
      localStorage.agreedTerms = newShowTerms;
      if (newShowTerms) {
        this.settingsDialog = true;
      }
    },
    selectedCustomer(newSelectedCustomer) {
    if(newSelectedCustomer.address){
      this.address = newSelectedCustomer.address;
    }
    },
    finalAddress(newFinalAddress) {
    if(newFinalAddress){
      console.log(newFinalAddress);
      this.isShownLoader = true;
      event === undefined;
      var api = `https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/scenarios/getElevatorInfoByAddress?address=${newFinalAddress}`;
      const params = new URLSearchParams();
      // call api to calculate scenarios
      axios
        .get(api, params)
        .then(response => {
          this.isShownLoader = false;
          if(response.data.Items[0]){
            this.refill = true;
            this.temporaryDimension = response.data.Items[0];
          }
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.isShownLoader = false;
    }
    },
    unit(newUnit) {
      localStorage.unit = newUnit;
    },
    language(newLanguage) {
      localStorage.language = newLanguage;
    },
    /*
    itemType(newItemType) {
      localStorage.itemType = newItemType;
    },
    */
  },
  // beforeDestroy() {
  //   this.unsubscribeAuth();
  // },
  methods: {
    handleSubmittedDimensions(item){
      this.selectedCustomer ={};
      this.selectedCustomer = {...item, key: this.randomKey()};
    },
    randomKey() {
      return (new Date()).getTime() + Math.floor(Math.random() * 10000).toString()
    },
    changeForm(form_id) {
      // Changes form to form_id and cleans the responseMessage so the error doesn't persist.
      this.form_id = form_id;
      this.responseMessage = "";
    },
    handleUpdateEvent(newValue) {
      this.getPendingOrders();
      // Update the parentProp when the child emits the event
      this.selectedCustomer = newValue;
    },
    handleUpdateEventExport(newValue) {
      this.address = newValue;
    },
    handleUpdateEventDock() {
      this.showDock = false;
    },
    validatePassword() {
      // The regex pattern to check the password against
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      this.displayValidationError = !pattern.test(this.password);
    },
    checkboxChanged(value) {
      if (value) {
        this.termsAndCondition = true;
        this.privacyPolicy = true;
      }
    },
    updateIndustryValue(value) {
      console.log('value--------', value)
      this.industry = value;
    },
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
        this.responseMessage = error?.message;
      } finally {
        this.address = null;
        this.signedIn = false;
        this.itemType = null;
        this.invalidSettings = true;
        this.invalidshareCustomerForm = true;
        this.$refs.elevatorMate.reset();
        localStorage.setItem('agreedTerms', false)
        localStorage.removeItem('authData')
      }
    },
    async signIn() {
      this.button_loader = true
      try {
        const user = await Auth.signIn(this.email, this.password);

        this.signedIn = true;
        this.settingsDialog = true;
        this.itemType = null;

        this.user = user; 
        this.username = this.user?.username;

        localStorage.setItem('agreedTerms', true)
        localStorage.setItem('authData', JSON.stringify(user)) // localStorage will only store strings
      } catch (error) {
        console.error('Error signing in:', error);
        this.responseMessage = error?.message;
      }
      this.button_loader = false
    },
    async signUp() {
      this.button_loader = true
      try {
        const { user } = await Auth.signUp({
          username: this.email,
          password: this.password,
          attributes: {
            email: this.email,
            'custom:industry': this.industry
          },
        });
        this.form_id = 2;
        console.log(user);
      } catch (error) {
        console.error('Error signing up:', error);
        this.responseMessage = error?.message;

      }
      this.button_loader = false
    },
    async confirmSignUp() {
      this.button_loader = true
      try {
        await Auth.confirmSignUp(this.email, this.code);
        // User confirmed, redirect or update state accordingly
        this.form_id = 0;
      } catch (error) {
        console.error('Error confirming sign up', error);
        this.responseMessage = error?.message;

      }
      this.button_loader = false
    },
    async resendConfirmationCode() {
      this.button_loader = true
      try {
        await Auth.resendSignUp(this.email);
        alert('A new confirmation code has been sent to your email.');
      } catch (error) {
        console.error('Error resending confirmation code', error);
        this.responseMessage = error?.message;

      }
      this.button_loader = false
    },
    async forgotPassword() {
      this.button_loader = true
      try {
        console.log("forget password");
        const result = await Auth.forgotPassword(this.email);
        console.log("forget password response", result);
        this.form_id = 4;
        alert('Confirmation code sent. Check your email.');
      } catch (error) {
        console.error('Error sending confirmation code', error);
        this.responseMessage = error?.message;

      }
      this.button_loader = false
    },
    async forgotPasswordSubmit() {
      this.button_loader = true
      try {
        const result = await Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword);
        console.log("forget password submitted", result);
        alert('Password reset successfully');
        // Redirect the user to the sign-in page or auto-sign-in them if desired
        this.form_id = 0;
      } catch (error) {
        console.error('Error resetting password', error);
        this.responseMessage = error?.message;
        //this.hasErrorCode = true;

      }
      this.button_loader = false
    },
    // Send application events to AWS Pinpoint for each item settings closure
    recordItemSettingsEvent() {
      // Record a generic event
      //Analytics.record('some-event-name');

      // Record a custom event
      Analytics.record({
        name: 'itemSettingsEvent',
        attributes: {
          address: String(this.address),
          item_type: String(this.itemType),
          language: String(this.language),
          unit: String(this.unit),
        },
      });
    },
    focusItemType() {
    // Remove focus from the "Delivery Address" field
    console.log('blur');
    this.$refs.addressBox.blur();
    },
    // Ensure settings form is validated upon settings change
    validateSettings() {
      if (this.$refs.landingForm.validate()) {
        this.invalidSettings = false;
      } else {
        this.invalidSettings = true;
      }
    },

    // Ensure settings form is validated upon settings change
    validateCustomerForm() {
      if (this.$refs.shareCustomerForm.validate()) {
        this.invalidshareCustomerForm = false;
      } else {
        this.invalidshareCustomerForm = true;
      }
    },

    // Entering the application, hide the settings dialog and fire an event
    closeSettingsDialog() {
      this.settingsDialog = false;
      this.finalAddress = this.address;
      setTimeout(() => {
        this.settingsDialog = true;
        this.address = '';
        this.itemType = null;
      }, 600000); // 600000 milliseconds = 10 minutes


      this.recordItemSettingsEvent();
    },

    // refresh dimensions and address
    refreshSettings() {
      this.settingsDialog = true;
      this.address = '';
      this.itemType = null;
      this.refresh = this.refresh + 1;
      this.selectedCustomer ={};
    },

    // hide the shareCustomerDialog dialog
    closeshareCustomerDialog() {
      this.shareCustomerDialog = false;
    },

    closesubmittedDimensionDialog() {
      this.invalidshareCustomerForm = false;
    },

    closeAutoFillDialog() {
      this.temporaryDimension = {};
      this.refill = false;
    },

    closeRefillDialog() {
      this.invalidshareCustomerForm = false;
      this.elevator.depth.value= this.temporaryDimension.internal_depth;
      this.elevator.height.value= this.temporaryDimension.internal_height;
      this.elevator.width.value= this.temporaryDimension.internal_width;
      this.door.depth.value= this.temporaryDimension.door_depth;
      this.door.height.value= this.temporaryDimension.door_height;
      this.door.width.value= this.temporaryDimension.door_width;
      this.refill = false;
    },

    async getPendingOrders() {
      this.isShownLoader = true;
      event === undefined;
      var api = `https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/scenarios/getPendingCustomerInfo?username=${this.user?.username}`;
      const params = new URLSearchParams();
      // call api to calculate scenarios
      axios
        .get(api, params)
        .then(response => {
          this.isShownLoader = false;
          if(response.data.Items){
            this.customerData = response.data
          }
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.isShownLoader = false;
    },

    async shareCustomerSubmit(event) {
      this.isShownLoader = true;
      event === undefined;
      var api = 'https://nco8ycbdj0.execute-api.ap-southeast-2.amazonaws.com/api/v1/scenarios/customerInfo';
      const params = new URLSearchParams();
      params.append('username', this.user?.username);
      params.append('order_id', this.order_id);
      params.append('name', this.name);
      params.append('email', this.email);
			params.append('address', this.address);
			params.append('item_type', this.itemType);
      params.append('unit', this.unit);
      params.append('language', this.language);
			console.log(params);
      // call api to calculate scenarios
      axios
        .post(api, params)
        .then(response => {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          this.isShownLoader = false;
        });
        this.closeshareCustomerDialog();
    },
  },
};
</script>

<style>
@font-face {
  font-family: 'Mohave Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Mohave Medium'),
    url('./fonts/mohave/Mohave-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Mohave Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Mohave Regular'),
    url('./fonts/mohave/Mohave-Regular.woff') format('woff');
}

.justify-content-between {
  justify-content: space-between;
}

.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, .8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-items-center {
  align-items: center;
}

.brand {
  font-family: 'Mohave Medium', Helvetica, Arial;
  font-size: 30px;
  color: gray;
}

:root {
  --amplify-primary-color: gray;
  --amplify-primary-tint: #100cf3;
  --amplify-primary-shade: #1901a3;
}

h2 {
  font-family: 'Mohave Regular';
  font-weight: 1;
  font-size: 1.5rem;
  color: #333333;
  margin-top: 0px;
}

.blur-content {
  filter: blur(5px);
}

/* Extra Small */
@media (max-width: 576px) {
  #dimensionExample {
    height: 350px;
  }

  #dimensionExample img {
    left: 20px;
    width: 311px;
  }

  #result img {
    width: 225px;
  }
}

/* Small */
@media (min-width: 576px) {
  #dimensionExample {
    height: 350px;
  }

  #dimensionExample img {
    left: 100px;
    width: 311px;
  }

  #result img {
    width: 225px;
  }
}

/* Medium */
@media (min-width: 768px) {
  #dimensionExample {
    height: 512px;
  }

  #dimensionExample img {
    left: 15px;
    width: 180px;
  }

  #result img {
    width: 130px;
  }
}

/* Large */
@media (min-width: 992px) {
  #dimensionExample {
    height: 512px;
  }

  #dimensionExample img {
    left: 20px;
    width: 240px;
  }

  #result img {
    width: 180px;
  }
}

/* Extra Large */
@media (min-width: 1200px) {
  #dimensionExample {
    height: 512px;
  }

  #dimensionExample img {
    left: 18px;
    width: 311px;
  }

  #result img {
    width: 225px;
  }
}

.tncClass {
  padding: 17px 40px;
}

h2 {
  font-weight: bold;
  padding-top: 10px;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.section {
  position: relative;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  width: 29rem;
}

.signin-heading {
  color: #152939;
  font-size: 1.15rem;
  font-weight: 700;
  margin: 1rem 0 1.5rem 0;
}

.label-form {
  font-size: 14px;
  color: #152939;
  display: block;
  padding-bottom: .5em;
  padding-top: .9em;
}
label.errored.label-form {
    color: red;
    font-weight: bold;
}
.login-field {
  display: block;
  width: 100%;
  padding: 1rem;
  font-size: 0.875rem;
  color: #152939;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 3.125rem;
  line-height: 1.1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select {
  appearance: auto;
}

.login-text {
  font-size: 12px;
  color: #828282;
}

.login-text button:hover {
  color: rgb(16, 12, 243);
  text-decoration: underline !important;
}

.submit-btn {
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: #fff;
  padding: 1rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: grey;
  border: 1px solid grey;
  border-radius: 0px;
}

.submit-outline-btn {
  background-color: transparent;
  color: grey;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.label-agreement {
  max-width: 260px;
  text-align: center;
}

#terms {
  width: 20px;
  height: 20px;
}

.tncClass h2 {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.tncClass div {
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.termsConditionClass {
  display: flex;
  justify-content: flex-end;
}

.login-field[readonly] {
  background: #eee;
  outline: none;
}

.v-application .alert-dialog {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  padding: 16px;
  color: #fff;
  background-color: #31465f !important;
}
.v-application .alert-dialog i
{
  color: #fff !important;
}

.v-application .alert-dialog .v-alert__content {
  font-size: 14px;
}

.submit-btn.active .text-btn {
  display: none;
}

.submit-btn.active {
  background-color: #100cf3;
  opacity: 0.65;
  text-align: ce;
}

.submit-btn .loader-btn {
  display: none;
}

.submit-btn.active .loader-btn {
  display: inline-block;
}

.profile-name {
  background-color:#b9b9b9; 
  color: #fff !important;
  cursor: auto !important;
}

.error-message {
  color: red !important;
  font-size: 12px !important;
  line-height: 1 !important;
}

.pac-logo:after {
  background-size: 70px !important;
}

.loader-btn {
  width: 22px;
  height: 22px;
  border: 5px solid rgba(255, 255, 255, .4);
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>