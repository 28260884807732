import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
import '@aws-amplify/ui-vue';
// import 'app.css'

import * as VueGoogleMaps from 'vue3-google-maps';
Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyA_qdi9IfajYsmmWulZciGlYLgcrQiKOiQ",
		libraries: "places"
	}
});

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        identityPoolId: 'ap-southeast-2:5e80299f-907f-4f94-84d9-e581d266f280',

        // REQUIRED - Amazon Cognito Region
        region: 'ap-southeast-2',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        //identityPoolRegion: 'ap-southeast-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-southeast-2_TQr2V3YMJ',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2aregfq1j31c0hcedeadniabdk',

        /*
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
    
        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: "strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },
    
        // OPTIONAL - customized storage object
        storage: MyStorage,
    
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    
        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata: { myCustomKey: 'myCustomValue' },
        */

        // ED: To hide the sign in with AWS button, comment the below
        /*
    
        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'test-auth-test.megably.com',
            scope: ['openid'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
        */
    },
});

const analyticsConfig = {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '3c96d2b7ded04139b42816064a9f2241',
        // Amazon service region
        region: 'ap-southeast-2',
        mandatorySignIn: false,
        //endpointId: `wgc-default`,
        //bufferSize: 1000,
        //flushInterval: 5000, // 5s 
        //flushSize: 100,
        //resendLimit: 5
    }
}
Analytics.configure(analyticsConfig);

// You can get the current config object
//const currentConfig = Auth.configure();

Vue.config.productionTip = false

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app')
